import React from 'react';
import PropTypes from "prop-types";

const ProductMicrodata = ({product}) => {

  const image = (product.productImage && product.productImage.fullpath) ? `${process.env.GATSBY_CMS_ASSETS_BASEURL}${product.productImage.fullpath}` : null;

  const structuredData =
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": product.productName,
      "image": [
        image
      ],
      "description": product.mainText,
      "brand": {
        "@type": "Brand",
        "name": "CERES"
      },
    };

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  )
};

ProductMicrodata.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductMicrodata;
