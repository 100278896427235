import React from "react";
import VisibilitySensor from "../visibilitySensor";
import ButtonLink from "../buttonLink"
import SEO from "../seo";
import ProductMicrodata from "../productMicrodata";
// import ArrowDown from "../arrowDown";
import BeerWhyCan from "../beerWhyCan";
import FullpageSection from "../fullpageSection"
import { SwiperBeerFeatures,  SwiperBeerFeaturesSlide } from "../swiperBeerFeatures"

import "./beer-details-template.scss";

function createMarkup(html) { return {__html: html}; }

const Markup = (props) => {
    const Tag = props.tag;
    return <Tag className = {props.class} dangerouslySetInnerHTML = {createMarkup(props.content)} />
};

const BeerDetailsTemplate = (props) => {

  const {
    beer
  } = props.pageContext;

  const {
    productCategory,
    productName,
    productImage,
    heroImage,
    heroTitle,
    heroText,
    mainTitle,
    mainText,
    linkToProduct,
    features,
    seoTitle,
    seoText,
  } = beer;

  return (

    <main>

      <SEO
        title={seoTitle || heroTitle}
        description={seoText || heroText}
      />

      <ProductMicrodata
        product={beer}
      />

      {heroImage && heroImage.fullpath &&

        <FullpageSection
          whichTitle={"h1"}
          copyTitle={heroTitle}
          copyText={heroText}
          normalBackground={true}
          mobileAlign={"align-right"}
          backgroundImage={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${heroImage.fullpath}`}
        />

      }

      <div className="details__container">
        <VisibilitySensor>
          {({ isVisible }) => (
            <div className={`details__wrapper ${isVisible ? 'slideUp sensorEnter' : 'slideUp'}`}>
              <h2 className="details__title">
                {mainTitle}
              </h2>
              { productImage && productImage.fullpath &&
                <img
                  className="details__beer"
                  alt={productName}
                  src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${productImage.fullpath}`}
                />
              }
              <Markup tag="p" content={mainText.replace(/(?:\r\n|\r|\n)/g, '<br />')} class="details__text" />

              {features && features.length &&
                <div className="details__features">
                  <SwiperBeerFeatures>
                    {features.map((feature, i) => {
                      if(feature.icon && feature.icon.fullpath) {
                        return (
                          <SwiperBeerFeaturesSlide
                            key={i}
                            srcImage={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${feature.icon.fullpath}`}
                            description={feature.description}
                          />
                        );
                      }
                      return false;
                    })}
                  </SwiperBeerFeatures>
                </div>
              }

              {linkToProduct &&
                <div className="details__cta">
                  <ButtonLink
                    to={linkToProduct.path}
                    label={linkToProduct.text}
                    trackingCategory={`Product page CTA ${productName}`}
                    trackingLabel={linkToProduct.text}
                  />
                </div>
              }
            </div>
          )}
        </VisibilitySensor>
      </div>

      {productCategory === 'can' &&
        <>
            {/*<ArrowDown />*/}
            <BeerWhyCan />
        </>
      }

    </main>
  );
};

export default BeerDetailsTemplate;
